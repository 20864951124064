import React, { useEffect, useState } from "react"
import { Box, Column, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { ApplicationType } from "@jobintrans/base-ui.types.api"
import { useParams } from "react-router-dom"
import { AdminOffersService } from "@jobintrans/base-ui.services.api-service"
import styled from "styled-components"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import { addSpacesFromPhoneNumber } from "@jobintrans/base-ui.utils.string"
import { IconBox } from "@jobintrans/base-ui.components.atoms.icon"
import { useTranslation } from "react-i18next"
import { Pagination } from "@jobintrans/base-ui.components.molecules.pagination"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import LoaderBlock from "components/shared/LoaderBlock"
import { formatDate } from "utils/date"
import { SentryService } from "services/SentryService"

export const ApplicationBlock = ({
  number,
  hasSurvey,
  application,
}: {
  number: number
  hasSurvey: boolean
  application: ApplicationType
}) => {
  const { offerId } = useParams()

  const { t } = useTranslation(["panel"])

  return (
    <ApplicationWrapper>
      <Label type="secondary" margin="0">
        {number}.
      </Label>
      <Label margin="0">
        {application.firstName} {application.lastName}
      </Label>
      <Label type="secondary" margin="0">
        {addSpacesFromPhoneNumber(application.phone)}
      </Label>
      <Label type="secondary" margin="0">
        {application.email}
      </Label>
      <Label type="secondary" margin="0">
        {formatDate(application.dtCreated)}
      </Label>
    </ApplicationWrapper>
  )
}

const OfferApplicationModal = ({ id = "", show, setShow }: {
  id?: string
  show: boolean
  setShow: (e: boolean) => void
}) => {
  const { t } = useTranslation(["panel"])

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState<number | null>(null)
  const [data, setData] = useState<ApplicationType[]>([])

  const fetchData = async (newPage: number) => {
    try {
      setLoading(true)
      const { content, totalPages, totalElements } = await AdminOffersService.getApplications(
        id,
        newPage,
      )
      setData(content)
      setTotalPages(totalPages)
      setCount(totalElements)
    } catch (e) {
      SentryService.error("[ERROR AdminOffersService.getApplications]:", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(page)
  }, [page])

  return (
    <>
      <Modal
        show={show}
        setShow={setShow}
        type="side"
        title={t("offers.offer.candidates.title")}
        text={t("offers.offer.candidates.text")}
        maxWidth="1200px"
      >
      <Wrapper>
        {
          !!count && count > 0 &&
          <Row justifyContent="flex-end">
            <P>
              {t("offers.offer.candidates.count")} {count}
            </P>
          </Row>
        }
        {loading ? (
          <Content>
            <LoaderBlock height={67} />
            <LoaderBlock height={67} />
            <LoaderBlock height={67} />
          </Content>
        ) : data.length > 0 ? (
          <>
            <Content>
              {data.map((el, index) => (
                <ApplicationBlock
                  key={index}
                  number={index + 1 + page * 10}
                  application={el}
                  hasSurvey={!!data.find(el => el.hasSurveyAnswer)}
                />
              ))}
            </Content>
            {data.length > 0 && (
              <Pagination
                defaultPage={page + 1}
                totalPages={totalPages}
                onChange={e => setPage(e - 1)}
              />
            )}
          </>
        ) : (
          <Column alignItems="center">
            <IconBox icon="clock" marginBottom="S" />
            <P textAlign="center" small>
              {t("offers.offer.candidates.empty")}
            </P>
          </Column>
        )}
      </Wrapper>
      </Modal>
    </>
  )
}

export default OfferApplicationModal

const Wrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.space.M};
`

const Content = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const ApplicationWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  grid-gap: ${({ theme }) => theme.space.XS};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr 1fr;
`
